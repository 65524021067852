
import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';

import { ListingCategory, ListingStatus } from 'client-website-ts-library/types';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class PropertiesForSale extends Mixins(View) {
  private listingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    OrderByStatements: [ListingOrderBy.CreateDateDesc],
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural, ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business],
  });
}
